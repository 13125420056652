<script setup>
import { useSettings } from "~/composables/useSettings";

const { settings } = useSettings();

const props = defineProps(["modelValue"]);

const selectedFont = ref(null);
const fontsColl = [
  { label: "Golos Text", value: "golos" },
  { label: "PT Serif", value: "ptserif" },
];

function watchProps() {
  watch(
    () => selectedFont.value,
    () => {
      settings.fontFamily = selectedFont.value;
    }
  );
}

onMounted(() => {
  // load settings
  selectedFont.value = settings.fontFamily;
  // watch settings
  watchProps();
});
</script>
<template>
  <v-segment
    v-model="selectedFont"
    :items="fontsColl"
    :options="{
      handleColor: '#fff',
      handleBgColor: '#03bfa4',
    }"
    item-label="label"
    item-value="value"
  ></v-segment>
</template>
<style scoped>
.select-button {
  border-radius: 6px;
  background-color: var(--root-white);
  width: 100%;
  color: black;
  padding: 6px 4px 8px 8px;
  justify-content: flex-start;
  box-shadow: 0px 4px 11px 0px #e1e5f1;
  height: 40px;
  align-items: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.font-option-el {
  font-size: 24px;
}
</style>
